export { c as CdkFixedSizeVirtualScroll, b as CdkScrollable, C as CdkScrollableModule, d as CdkVirtualForOf, e as CdkVirtualScrollViewport, k as CdkVirtualScrollable, g as CdkVirtualScrollableElement, f as CdkVirtualScrollableWindow, h as DEFAULT_RESIZE_TIME, D as DEFAULT_SCROLL_TIME, F as FixedSizeVirtualScrollStrategy, S as ScrollDispatcher, a as ScrollingModule, j as VIRTUAL_SCROLLABLE, i as VIRTUAL_SCROLL_STRATEGY, V as ViewportRuler, _ as _fixedSizeVirtualScrollStrategyFactory } from './scrolling-module-aa1faac1.mjs';
export { D as ɵɵDir } from './bidi-module-56dd006c.mjs';
import '@angular/core';
import 'rxjs';
import 'rxjs/operators';
import './element-08c51fcd.mjs';
import './platform-610a08ae.mjs';
import '@angular/common';
import './directionality-0a678adc.mjs';
import './scrolling-61955dd1.mjs';
import './recycle-view-repeater-strategy-c1712813.mjs';
import './data-source-5320b6fd.mjs';
