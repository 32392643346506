import { HttpClient, HttpDownloadProgressEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventPhoto, Photo } from '@pixomaticc/ngx-ui';
import Utils from '@uzenith360/utils'
import { HandledHttpResponse, HttpError, httpRetry } from '@uzenith360/http-utils';
import { Observable, catchError, of, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import EventUserCode from './event-user-code.interface';
import InitPaymentData from './init-payment-data.interface';
import paymentStatus from './payment-status.enum';
import OfflineInitPaymentData from './offline-init-payment-data.interface';
import GalleryReview from './rating-and-review-bottom-sheet/gallery-review.interface';

@Injectable({
  providedIn: 'root'
})
export class EventPhotosService {
  // private static creativesAppTokenStoreId: string = 'iop';

  constructor(private http: HttpClient) { }

  public requestMoreAction(
    action: 'frame' | 'photo book' | 'print',
    contactId: string,
    photoURLs: string[],
    photographerId: string,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      action,
      photoURLs,
      photographerId,
    };

    return this.http.post(`${environment.usersBaseUrl}/request-more-action`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public requestMoreActionGalleryNotification(
    action: 'frame' | 'photo book' | 'print',
    contactId: string,
    eventId: string,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      action,
      eventId,
    };

    return this.http.post(`${environment.usersBaseUrl}/request-more-action-gallery-notification`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public requestMoreActionPhotoNotification(
    action: 'frame' | 'photo book' | 'print',
    contactId: string,
    eventId: string,
    photoId: string,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      action,
      eventId,
      photoId,
    };

    return this.http.post(`${environment.usersBaseUrl}/request-more-action-photo-notification`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public offlinePaymentNotification(
    contactId: string,
    eventId: string,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      eventId,
    };

    return this.http.post(`${environment.usersBaseUrl}/offline-payment-notification`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public addGalleryReview(
    contactId: string,
    eventId: string,
    rating: number,
    review?: string,
    tags?: string[],
  ): Observable<HandledHttpResponse<GalleryReview>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      eventId,
      rating
    };

    if (!!review) {
      body['review'] = review;
    }

    if (!!tags) {
      body['tags'] = tags;
    }

    return this.http.post(`${environment.eventsBaseUrl}/gallery-review`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: GalleryReview, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as GalleryReview,
            },
          )
        }),
        map<{ data: GalleryReview | undefined, statusCode: number, message: any }, HandledHttpResponse<GalleryReview>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public updateCollaborationEvent(
    contactId: string,
    eventId: string,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      eventId,
    };

    return this.http.patch(`${environment.eventsBaseUrl}/collaboration`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  // public fetchEventMeta(slugOrCode: string, subdomain?: string)
  //   : Observable<HandledHttpResponse<FetchForMetaResult>> {
  //   const params: { [key: string]: string | number } = {
  //     slugOrCode,
  //   };

  //   if (!!subdomain) {
  //     params['subdomain'] = subdomain;
  //   }

  //   return this.http.get(`${environment.eventsBaseUrl}/fetch-by-user-code-meta`, { params })
  //     .pipe(
  //       httpRetry(),
  //       catchError((err: HttpErrorResponse, caught: Observable<{ data: FetchForMetaResult, statusCode: number, message: any; }>) => {
  //         return of(
  //           {
  //             statusCode: err.status,
  //             message: err.error.message ?? 'Problem completing this request, please try again',
  //             data: {} as FetchForMetaResult,
  //           },
  //         )
  //       }),
  //       map<{ data: FetchForMetaResult, statusCode: number, message: any }, HandledHttpResponse<FetchForMetaResult>>(
  //         ({ data: eventUserData, statusCode, message }) => (
  //           {
  //             data: eventUserData,
  //             message,
  //             statusCode: statusCode ?? 200,
  //           }
  //         ),
  //       ),
  //     );
  // }

  public fetchEvent(userCode: string, setId: string | undefined, setTitle: string | undefined, origin: string | undefined)
    : Observable<HandledHttpResponse<Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}>> {
    const params: { [key: string]: string | number } = {
      userCode,
    };

    if (!!setId) {
      params['setId'] = setId
    }

    if (!!setTitle) {
      params['setTitle'] = setTitle;
    }

    if(!!origin) {
      params['origin'] = origin;
    }

    return this.http.get(`${environment.eventsBaseUrl}/fetch-by-user-code`, { params })
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message ?? 'Problem completing this request, please try again',
              data: {} as Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean},
            },
          )
        }),
        map<{ data: Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}, statusCode: number, message: any }, HandledHttpResponse<Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}>>(
          ({ data: eventUserData, statusCode, message }) => (
            {
              data: {
                ...eventUserData,
                offlinePaymentAmount: eventUserData.offlinePaymentAmount
                  && Utils.roundOff2Dp(eventUserData.offlinePaymentAmount / 100),
                // photoMatches: eventUserData.photoMatches?.map(
                //   (photo) => (
                //     {
                //       ...photo,
                //       ignoreSelectMode: !!photo.highResURL,
                //     }
                //   ),
                // ),
              },
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public fetchEventForGalleryPreview(id: string, type: 'cover' | 'gallery', date: string)
    : Observable<HandledHttpResponse<Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}>> {
    const params: { [key: string]: string | number } = {
      i: id, t: type, d: date,
    };

    return this.http.get(`${environment.eventsBaseUrl}/fetch-fgp`, { params/*, headers: this.getCreativesAuth()*/ })
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message ?? 'Problem completing this request, please try again',
              data: {} as Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean},
            },
          )
        }),
        map<{ data: Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}, statusCode: number, message: any }, HandledHttpResponse<Omit<EventUserCode, 'downloadPIN'> & {downloadPIN: boolean}>>(
          ({ data: eventUserData, statusCode, message }) => (
            {
              data: eventUserData,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public saveSelfie(
    name: string,
    height: number,
    width: number,
    contactId: string,
    // eventId: string,
    photoURL: string,
  ): Observable<HandledHttpResponse<string>> {
    const body: { [key: string]: string | number } = {
      name,
      height,
      width,
      contactId,
      // eventId,
      photoURL,
    };

    return this.http.post(`${environment.photosBaseUrl}/user-selfie`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: string, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as string,
            },
          )
        }),
        map<{ data: string | undefined, statusCode: number, message: any }, HandledHttpResponse<string>>(
          ({ data: photoId, statusCode, message }) =>
          (
            {
              data: photoId,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public savePhoto(
    name: string,
    height: number,
    width: number,
    highResURL: string
  ): Observable<HandledHttpResponse<EventPhoto>> {
    const body: { [key: string]: string | number } = {
      name,
      height,
      width,
      highResURL,
    };

    return this.http.post(`${environment.photosBaseUrl}/create`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: EventPhoto, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as EventPhoto,
            },
          )
        }),
        map<{ data: EventPhoto | undefined, statusCode: number, message: any }, HandledHttpResponse<EventPhoto>>(
          ({ data: photoEvent, statusCode, message }) =>
          (
            {
              data: photoEvent,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public removePhotoIdMatch(
    contactId: string,
    eventId: string,
    setId: string | undefined,
    photoId: string,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number } = {
      contactId,
      eventId,
      photoId,
    };

    if (!!setId) {
      body['setId'] = setId;
    }

    return this.http.delete(`${environment.photosBaseUrl}/remove-photo-id-match`, { body })
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: false as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public cancelOfflinePayment(
    offlinePaymentId: string,
  ): Observable<HandledHttpResponse<boolean>> {
    return this.http.delete(`${environment.paymentsBaseUrl}/client-cancel-offline-payment/${offlinePaymentId}`)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: false as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: iscancelled, statusCode, message }) =>
          (
            {
              data: iscancelled,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public fetchPhotoMatchesPhotos(
    contactId: string,
    eventId: string,
    setId: string | undefined,
    reference: string,
    isDownloadHighRes: boolean,
  ): Observable<HandledHttpResponse<string[]>> {
    const params: { [key: string]: string | number } = {
      contactId,
      eventId,
      reference,
      resId: isDownloadHighRes ? '6639b4c0228872c8af70c635' : Utils.generateRandomString(24, '0123456789abcdef'),
    };

    if (!!setId) {
      params['setId'] = setId;
    }

    return this.http.get(`${environment.photosBaseUrl}/fetch-photo-matches-photos`, { params })
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: string[], statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: [] as string[],
            },
          )
        }),
        map<{ data: string[] | undefined, statusCode: number, message: any }, HandledHttpResponse<string[]>>(
          ({ data: photos, statusCode, message }) =>
          (
            {
              data: photos, //photos?.map((photo) => ({ ...photo, ignoreSelectMode: !!photo.highResURL })),
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public fetchPhotoMatches(
    contactId: string,
    eventId: string,
    setId: string | undefined,
    page: number,
    limit: number,
    reference?: string,
  ): Observable<HandledHttpResponse<Photo[]>> {
    const params: { [key: string]: string | number } = {
      contactId,
      eventId,
      page,
      limit,
    };

    if (!!reference) {
      params['reference'] = reference;
    }

    if (!!setId) {
      params['setId'] = setId;
    }

    return this.http.get(`${environment.eventsBaseUrl}/fetch-photo-matches`, { params })
      .pipe(
        httpRetry(15),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: Photo[], statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: [] as Photo[],
            },
          )
        }),
        map<{ data: Photo[] | undefined, statusCode: number, message: any }, HandledHttpResponse<Photo[]>>(
          ({ data: photos, statusCode, message }) =>
          (
            {
              data: photos/*?.map((photo) => ({ ...photo, ignoreSelectMode: !!photo.highResURL }))*/,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public uploadUserProfilePhotoId(
    id: string,
    profilePhotoId?: string,
    previousProfilePhotoId?: string,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number } = {
      id,
    };

    if (!!profilePhotoId) {
      body['profilePhotoId'] = profilePhotoId;
    }

    if (!!previousProfilePhotoId) {
      body['previousProfilePhotoId'] = previousProfilePhotoId;
    }

    return this.http.patch(`${environment.usersBaseUrl}/users/update-profile-photo-id`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: false as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public fetchUserPhotosFromEvent(
    setId: string | undefined,
    eventId: string,
    contactId: string,
  ): Observable<HandledHttpResponse<Photo[]>> {
    const params: { [key: string]: string | number } = {
      wbn: eventId,
      hni: contactId,
    };

    if (!!setId) {
      params['setId'] = setId;
    }

    return this.http.get(`${environment.photosBaseUrl}/fetch-user-photos-from-event`, { params })
      .pipe(
        httpRetry(undefined, 5000),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: EventPhoto[], statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: [] as EventPhoto[],
            },
          )
        }),
        map<{ data: EventPhoto[] | undefined, statusCode: number, message: any }, HandledHttpResponse<EventPhoto[]>>(
          ({ data: photoEvent, statusCode, message }) =>
          (
            {
              data: photoEvent,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
        map(
          ({ message, statusCode, data }) => ({
            message,
            statusCode,
            data: data?.map(
              (
                {
                  _id,
                  type,
                  width,
                  height,
                  mimetype,
                  highResURL,
                  midResURL,
                  lowResURL,
                  name,
                  createdAt,
                },
              ) => (
                {
                  height,
                  name,
                  timestamp: !!createdAt ? new Date(createdAt).getTime() : Date.now(),
                  width,
                  type,
                  mimetype,
                  highResURL,
                  midResURL,
                  lowResURL,
                  // ignoreSelectMode: !!highResURL,
                  _id,

                }
              ),
            )
          })
        ),
      );
  }

  public editMeta(
    id: string, isClientDownloaded?: boolean, isClientSelected?: boolean, isClientReviewed?: boolean, isSkipClientReview?: boolean
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: boolean | string } = {
      id,
    };

    if (isClientDownloaded !== undefined) {
      body['isClientDownloaded'] = isClientDownloaded;
    }

    if (isClientSelected !== undefined) {
      body['isClientSelected'] = isClientSelected;
    }

    if (isClientReviewed !== undefined) {
      body['isClientReviewed'] = isClientReviewed;
    }

    if (isSkipClientReview !== undefined) {
      body['isSkipClientReview'] = isSkipClientReview;
    }

    return this.http.patch(`${environment.eventsBaseUrl}/edit-meta`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: false as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public selectMatchedUserPhoto(
    contactId: string,
    eventId: string,
    setId: string | undefined,
    photoId: string,
    isSelected: boolean,
  ): Observable<HandledHttpResponse<boolean>> {
    const body: { [key: string]: string | number | boolean | string[] } = {
      contactId,
      eventId,
      photoId,
      isSelected,
    };

    if (!!setId) {
      body['setId'] = setId;
    }

    return this.http.patch(`${environment.photosBaseUrl}/select-matched-user-photo`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: boolean, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as boolean,
            },
          )
        }),
        map<{ data: boolean | undefined, statusCode: number, message: any }, HandledHttpResponse<boolean>>(
          ({ data: isSelectMatchedUserPhoto, statusCode, message }) =>
          (
            {
              data: isSelectMatchedUserPhoto,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public commentMatchedUserPhoto(
    contactId: string,
    eventId: string,
    setId: string | undefined,
    photoId: string,
    text: string,
  ): Observable<HandledHttpResponse<string>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      eventId,
      text,
      photoId,
    };

    if (!!setId) {
      body['setId'] = setId;
    }

    return this.http.post(`${environment.photosBaseUrl}/comment-matched-user-photo`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: string, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as string,
            },
          )
        }),
        map<{ data: string | undefined, statusCode: number, message: any }, HandledHttpResponse<string>>(
          ({ data: newCommentId, statusCode, message }) =>
          (
            {
              data: newCommentId,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public downloadMatchedUserPhotos(
    contactId: string,
    eventId: string,
    setId: string | undefined = Utils.generateRandomString(24, '0123456789abcdef'),
    photoIds: string[],
    email?: string,
    downloadPIN?: string,
  ): Observable<HandledHttpResponse<string>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      eventId,
      photoIds,
    };

    if (!!setId) {
      body['setId'] = setId;
    }

    if (!!email) {
      body['email'] = email;
    }

    if (!!downloadPIN) {
      body['downloadPIN'] = downloadPIN;
    }

    return this.http.post(`${environment.photosBaseUrl}/download-matched-user-photos`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: string, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as string,
            },
          )
        }),
        map<{ data: string | undefined, statusCode: number, message: any }, HandledHttpResponse<string>>(
          ({ data: newDownloadId, statusCode, message }) =>
          (
            {
              data: newDownloadId,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public zipAndDownloadFiles(
    filesString: string,
    trustyDigits: string,
    contactIdEventIdPhotographerId: string,
    fileName: string,
  ): Observable<HandledHttpResponse<string>> {
    const body: { [key: string]: string | number | string[] } = {
      i: filesString,
      _: trustyDigits,
      b: contactIdEventIdPhotographerId,
    };

    if (!!fileName) {
      body['o'] = fileName;
    }

    return this.http.post(`${environment.photosBaseUrl}/zip-and-download-files`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: string, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as string,
            },
          )
        }),
        map<{ data: string | undefined, statusCode: number, message: any }, HandledHttpResponse<string>>(
          ({ data: downloadURL, statusCode, message }) =>
          (
            {
              data: downloadURL,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  // public removeZippedDownload(
  //   fileString: string,
  // ): Observable<HandledHttpResponse<void>> {
  //   const body: { [key: string]: string | number | string[] } = {
  //     i: fileString,
  //   };

  //   return this.http.delete(`${environment.photosBaseUrl}/zip-and-download-files`, body)
  //     .pipe(
  //       httpRetry(),
  //       catchError((err: HttpErrorResponse, caught: Observable<{ data: undefined, statusCode: number, message: any; }>) => {
  //         return of(
  //           {
  //             statusCode: err.status,
  //             message: err.error.message,
  //             data: undefined,
  //           },
  //         )
  //       }),
  //       map<{ data: undefined, statusCode: number, message: any }, HandledHttpResponse<void>>(
  //         ({ data: downloadURL, statusCode, message }) =>
  //         (
  //           {
  //             data: downloadURL,
  //             message,
  //             statusCode: statusCode ?? 200,
  //           }
  //         ),
  //       ),
  //     );
  // }

  public offlineInitPayment(
    contactId: string,
    eventId: string,
    photoIds: string[] | undefined,
    photographerId: string,
  ): Observable<HandledHttpResponse<OfflineInitPaymentData>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      eventId,
      photographerId,
      type: 'PHOTO',
    };

    if (!!photoIds) {
      body['data'] = photoIds;
    }

    return this.http.post(`${environment.paymentsBaseUrl}/init-offline`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: OfflineInitPaymentData, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as OfflineInitPaymentData,
            },
          )
        }),
        map<{ data: OfflineInitPaymentData | undefined, statusCode: number, message: any }, HandledHttpResponse<OfflineInitPaymentData>>(
          ({ data: offlineInitPaymentData, statusCode, message }) =>
          (
            {
              data: !!offlineInitPaymentData
                ? {
                  ...offlineInitPaymentData,
                  amount: offlineInitPaymentData.amount
                    && Utils.roundOff2Dp(offlineInitPaymentData.amount / 100),
                }
                : { id: '', amount: 0, bankAccount: {} as OfflineInitPaymentData['bankAccount'] },
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public initPayment(
    contactId: string,
    eventId: string,
    photoIds: string[] | undefined,
    photographerId: string,
  ): Observable<HandledHttpResponse<InitPaymentData>> {
    const body: { [key: string]: string | number | string[] } = {
      contactId,
      eventId,
      photographerId,
      // data: photoIds,
      type: 'PHOTO',
    };

    if (!!photoIds) {
      body['data'] = photoIds;
    }

    return this.http.post(`${environment.paymentsBaseUrl}/init`, body)
      .pipe(
        httpRetry(),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: InitPaymentData, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as InitPaymentData,
            },
          )
        }),
        map<{ data: InitPaymentData | undefined, statusCode: number, message: any }, HandledHttpResponse<InitPaymentData>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public verifyPayment(
    reference: string,
  ): Observable<HandledHttpResponse<paymentStatus>> {
    return this.http.get(`${environment.paymentsBaseUrl}/verify-paystack/${reference}`)
      .pipe(
        httpRetry(undefined, 5000),
        catchError((err: HttpErrorResponse, caught: Observable<{ data: paymentStatus, statusCode: number, message: any; }>) => {
          return of(
            {
              statusCode: err.status,
              message: err.error.message,
              data: {} as paymentStatus,
            },
          )
        }),
        map<{ data: paymentStatus | undefined, statusCode: number, message: any }, HandledHttpResponse<paymentStatus>>(
          ({ data: isUpdated, statusCode, message }) =>
          (
            {
              data: isUpdated,
              message,
              statusCode: statusCode ?? 200,
            }
          ),
        ),
      );
  }

  public downloadFile(fileURL: string): Observable<HttpResponse<void> | HttpDownloadProgressEvent> {
    return this.http.get(
      fileURL,
      {
        responseType: 'blob',
        headers: {
          // stop HTTPInterceptors from catching it and adding headers
          'skip-interceptors': "true",
          // stop angular service worker from intercepting this request
          // wierdly, this issue only showed up on mobile
          'ngsw-bypass': "true",
        },
        reportProgress: true,
        observe: 'events',
      }
    ).pipe(
      httpRetry(5, undefined, [0, 502, 503, 504, 500, 499]/*1, 2000, [0, 502, 500, 499]*/),
      catchError((err: HttpErrorResponse, caught: Observable<HttpResponse<void> | HttpDownloadProgressEvent>) => {
        switch (err.status) {
          case 500:
            return throwError(() => new HttpError('Problem uploading file, please try again', err.status));
          case 0:
          default:
            return throwError(
              () => new HttpError(
                (err.error?.message?.join && err.error?.message?.join(', ')) ?? err.error?.message ?? err?.message ?? 'Problem uploading file, please check your internet connection and try again',
                err.status,
              ),
            );
        };
      }),
    );
  }

  // private getCreativesAuth(): { [header: string]: string } | undefined {
  //   try {
  //     return {
  //       Authorization: `Bearer ${JSON.parse(localStorage.getItem(EventPhotosService.creativesAppTokenStoreId)!).access_token}`
  //     }
  //   } catch (e) {
  //     return undefined;
  //   }
  // }
}
