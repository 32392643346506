import addDaysToDate from "./add-days-to-date";
import addHoursToDate from "./add-hours-to-date";
import addMinutesToDate from "./add-minutes-to-date";
import addMonthsToDate from "./add-months-to-date";
import addSecondsToDate from "./add-seconds-to-date";
import addYearsToDate from "./add-years-to-date";
import capitalize from "./capitalize";
import discountedPrice from "./discounted-price";
import formatMoney from './format-money';
import generateRandomString from "./generate-random-string";
import generateSequentialString from "./generate-sequential-string";
import getAAnForWord from "./get-a-an-for-word";
import getDateTimeAgo from "./get-date-time-ago";
import getDateDayDiff from "./get-date-day-diff";
import getDateFullText from "./get-date-full-text";
import getShortTextDate from "./get-short-text-date";
import getFormattedDateString from "./get-formatted-date-string";
import getShortTextDayMonthOptionalyear from "./get-short-text-day-month-optionalyear";
import getDayText from "./get-day-text";
import getEndOfDayDate from "./get-end-of-day-date";
import getMonthDiff from "./get-month-diff";
import getPaginationParameters from "./get-pagination-parameters";
import getStartOfDayDate from "./get-start-of-day-date";
import getFormattedTimeString from "./get-formatted-time-string";
import isString from "./is-string";
import normalizePhoneNumber from "./normalize-phone-number";
import roundOff2Dp from "./round-off-2-dp";
import splitCompoundString from "./split-compound-string";
import textSubtract from "./text-subtract";
import toTitleCase from "./to-title-case";
import trimAndLowercase from "./trim-and-lowercase";
import trimAndUppercase from "./trim-and-uppercase";
import getFirstDayOfMonthDate from "./get-first-day-of-month-date";
import getFirstDayOfQuarterDate from "./get-first-day-of-quarter-date";
import getFirstDayOfWeekDate from "./get-first-day-of-week-date";
import getFirstDayOfYearDate from "./get-first-day-of-year-date";
import getSecondarySequentialIndex from "./get-secondary-sequential-index";
import getSequentialStringOptimalLength from "./get-sequential-string-optimal-length";
import removeUndefinedNullValuesFromObject from "./remove-undefined-null-values-from-object";
import getBetterDate from "./get-better-date";
import toSentenceCase from "./to-sentence-case";
// export function helloWorld() {
//     const message = 'Hello World from my example modern npm package!';
//     return message;
// }
// export function goodBye() {
//     const message = 'Goodbye from my example modern npm package!';
//     return message;
// }
export default {
  // helloWorld,
  // goodBye,
  addDaysToDate,
  addHoursToDate,
  addMinutesToDate,
  addMonthsToDate,
  addSecondsToDate,
  addYearsToDate,
  capitalize,
  discountedPrice,
  formatMoney,
  generateRandomString,
  getSequentialStringOptimalLength,
  getSecondarySequentialIndex,
  generateSequentialString,
  getAAnForWord,
  getDateDayDiff,
  getDateTimeAgo,
  getDateFullText,
  getShortTextDate,
  getFormattedDateString,
  removeUndefinedNullValuesFromObject,
  getShortTextDayMonthOptionalyear,
  getBetterDate,
  getDayText,
  getEndOfDayDate,
  getMonthDiff,
  getPaginationParameters,
  getStartOfDayDate,
  getFormattedTimeString,
  isString,
  normalizePhoneNumber,
  roundOff2Dp,
  splitCompoundString,
  textSubtract,
  toTitleCase,
  toSentenceCase,
  trimAndLowercase,
  trimAndUppercase,
  getFirstDayOfMonthDate,
  getFirstDayOfQuarterDate,
  getFirstDayOfWeekDate,
  getFirstDayOfYearDate
};