export { a as MAT_ICON_DEFAULT_OPTIONS, b as MAT_ICON_LOCATION, c as MAT_ICON_LOCATION_FACTORY, d as MatIcon, M as MatIconModule } from './icon-module-881c6455.mjs';
export { d as ICON_REGISTRY_PROVIDER, I as ICON_REGISTRY_PROVIDER_FACTORY, M as MatIconRegistry, c as getMatIconFailedToSanitizeLiteralError, b as getMatIconFailedToSanitizeUrlError, g as getMatIconNameNotFoundError, a as getMatIconNoHttpProviderError } from './icon-registry-e1064d47.mjs';
import '@angular/core';
import '@angular/common';
import 'rxjs';
import 'rxjs/operators';
import './common-module-a39ee957.mjs';
import '@angular/cdk/a11y';
import '@angular/cdk/bidi';
import '@angular/common/http';
import '@angular/platform-browser';
